// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDz-RD7DO3NiFCBVNX-VBlI7k8JvLPAQts",
  authDomain: "uaebusiness-ac4ee.firebaseapp.com",
  projectId: "uaebusiness-ac4ee",
  storageBucket: "uaebusiness-ac4ee.appspot.com",
  messagingSenderId: "223205649323",
  appId: "1:223205649323:web:69727fa64fc5430059c0ee",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, storage, auth };
