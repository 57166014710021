// src/components/NewsAddPage.js
import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Input,
  Textarea,
  Select,
  Image,
  FormControl,
  FormLabel,
  Spinner,
  useToast,
  Center,
} from "@chakra-ui/react";
import { storage, db } from "../firebaseConfig"; // Ensure Firebase is configured
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import NewsPageContext from "../providers/NewsPageContext";
import { categories } from "../constants"; // Import categories

const NewsAddPage = () => {
  const toast = useToast();
  const { loading } = useContext(NewsPageContext);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    link: "",
    category: categories[0],
    image: null,
  });
  const [imageFile, setImageFile] = useState(null);
  const [formLoading, setFormLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      setFormData({
        ...formData,
        image: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    let imageUrl = null;

    try {
      if (imageFile) {
        const ext = imageFile.name.split(".").pop();
        const fileName = `${Date.now()}.${ext}`; // Unique file name
        const metadata = { contentType: `image/${ext}` };
        const storageRef = ref(storage, `images/${fileName}`);
        const uploadTask = uploadBytesResumable(
          storageRef,
          imageFile,
          metadata
        );

        await uploadTask;
        imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
      }

      const newData = {
        title: formData.title,
        content: formData.content,
        link: formData.link,
        category: formData.category,
        image: imageUrl,
        date: new Date(), // Server timestamp equivalent
      };

      await addDoc(collection(db, "news"), newData);

      setFormLoading(false);
      toast({
        title: "News added successfully",
        status: "success",
        isClosable: true,
        duration: 3000,
      });

      // Reset form data
      setFormData({
        title: "",
        content: "",
        link: "",
        category: categories[0],
        image: null,
      });
      setImageFile(null);
    } catch (error) {
      console.error("Error adding document: ", error);
      setFormLoading(false);
      toast({
        title: "Error adding news",
        description: error.message,
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
  };

  if (loading || formLoading) {
    return <Spinner />;
  }

  return (
    <Box as="form" onSubmit={handleSubmit} p={4}>
      <FormControl mb={4} isRequired>
        <FormLabel>Title</FormLabel>
        <Input
          name="title"
          value={formData.title}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl mb={4} isRequired>
        <FormLabel>Content</FormLabel>
        <Textarea
          name="content"
          value={formData.content}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel>Link</FormLabel>
        <Input name="link" value={formData.link} onChange={handleInputChange} />
      </FormControl>
      <FormControl mb={4} isRequired>
        <FormLabel>Category</FormLabel>
        <Select
          name="category"
          value={formData.category}
          onChange={handleInputChange}
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl mb={4} isRequired>
        <FormLabel>Image</FormLabel>
        <Input type="file" accept="image/*" onChange={handleImageChange} />
        {formData.image && (
          <Center mt={4}>
            <Image src={formData.image} alt="News Image" objectFit="cover" />
          </Center>
        )}
      </FormControl>
      <Center mt={4}>
        <Button type="submit" colorScheme="blue" isLoading={formLoading}>
          Add News
        </Button>
      </Center>
    </Box>
  );
};

export default NewsAddPage;
