// src/components/NewsPage.js
import React, { useContext } from "react";
import {
  Box,
  Button,
  Spinner,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Stack,
  useBreakpointValue,
  Text,
} from "@chakra-ui/react";
import { Route, Routes, Link, useNavigate } from "react-router-dom";
import { HamburgerIcon, InfoIcon } from "@chakra-ui/icons";
import { FaImage } from "react-icons/fa";
import { SlLogout } from "react-icons/sl";
import NewsPageContext from "../providers/NewsPageContext";
import NewsItem from "./NewsItem"; // Import the NewsItem component
import NewsPage from "./NewsPage";
import ImagesPage from "./ImagesPage";
import { auth } from "../firebaseConfig";
import NewsEditPage from "./NewsEditPage";
import NewsAddPage from "./NewsAddPage";
import { IoHome } from "react-icons/io5";

const AdminPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isDrawer = useBreakpointValue({ base: true, md: false });
  const navigate = useNavigate();
  const { news, loading, deleteNews } = useContext(NewsPageContext);

  const logout = async () => {
    await auth.signOut();
    navigate("/login");
  };

  const goHome = () => navigate("/");
  const SidebarContent = (
    <Stack spacing={4} p={4}>
      <Button
        width="100%"
        justifyContent="flex-start"
        bg="white"
        color="black"
        leftIcon={<IoHome />}
        _hover={{ bg: "gray.100" }}
        onClick={goHome}
      >
        Home
      </Button>
      <Link to="news">
        <Button
          width="100%"
          justifyContent="flex-start"
          bg="white"
          color="black"
          leftIcon={<InfoIcon />}
          _hover={{ bg: "gray.100" }}
          onClick={onClose}
        >
          News
        </Button>
      </Link>
      <Link to="images">
        <Button
          width="100%"
          justifyContent="flex-start"
          bg="white"
          color="black"
          leftIcon={<FaImage />}
          _hover={{ bg: "gray.100" }}
          onClick={onClose}
        >
          Images
        </Button>
      </Link>
      <Button
        width="100%"
        justifyContent="flex-start"
        bg="white"
        color="black"
        leftIcon={<SlLogout />}
        _hover={{ bg: "gray.100" }}
        onClick={logout}
      >
        Logout
      </Button>
    </Stack>
  );

  return (
    <Box display="flex" height="100vh">
      {isDrawer ? (
        <>
          <IconButton
            icon={<HamburgerIcon />}
            aria-label="Open menu"
            onClick={onOpen}
            m={4}
          />
          <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader borderBottomWidth="1px">Navigation</DrawerHeader>
              <DrawerBody>{SidebarContent}</DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      ) : (
        <Box width="240px" borderRight="1px solid #e0e0e0">
          {SidebarContent}
        </Box>
      )}
      <Box flex="1" p={4}>
        <Routes>
          <Route path="/" element={<NewsPage />} />
          <Route path="news" element={<NewsPage />} />
          <Route path="images" element={<ImagesPage />} />
          <Route path="news/:id" element={<NewsEditPage />} />
          <Route path="news/add_news" element={<NewsAddPage />} />
        </Routes>
      </Box>
    </Box>
  );
};
export default AdminPage;
