import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Center,
  Spinner,
  Text,
  Input,
  Textarea,
  Button,
  Select,
  FormControl,
  FormLabel,
  Image,
  useToast,
} from "@chakra-ui/react";
import {
  getDownloadURL,
  ref,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { storage, db } from "../firebaseConfig"; // Ensure you have Firebase configured
import NewsPageContext from "../providers/NewsPageContext";
import { categories, generateUniqueFileName } from "../constants"; // Ensure you have a categories list

const NewsEditPage = () => {
  const { id } = useParams();
  const toast = useToast();
  const { loading } = useContext(NewsPageContext);
  const [newsItem, setNewsItem] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    link: "",
    category: categories[0],
    image: null,
  });
  const [imageFile, setImageFile] = useState(null);
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "news", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setNewsItem(docSnap.data());
        setFormData({
          title: docSnap.data().title,
          content: docSnap.data().content,
          link: docSnap.data().link,
          category: docSnap.data().category,
          image: docSnap.data().image,
        });
      }
    };
    fetchData();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
      setFormData({
        ...formData,
        image: URL.createObjectURL(e.target.files[0]),
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormLoading(true);
    let imageUrl = formData.image;

    try {
      if (imageFile) {
        const ext = imageFile.name.split(".").pop();
        const fileName = generateUniqueFileName(ext);
        const metadata = {
          contentType: `image/${ext}`,
        };
        const storageRef = ref(storage, `images/${fileName}`);
        const uploadTask = uploadBytesResumable(
          storageRef,
          imageFile,
          metadata
        );

        await uploadTask;
        imageUrl = await getDownloadURL(uploadTask.snapshot.ref);

        // Delete old image if a new one was uploaded
        if (newsItem.image) {
          try {
            const oldImageRef = ref(storage, newsItem.image);
            await deleteObject(oldImageRef);
          } catch (error) {
            console.log("Old image couldn't be deleted: " + newsItem.image);
          }
        }
      }

      const updatedData = {
        title: formData.title,
        content: formData.content,
        link: formData.link,
        category: formData.category,
        image: imageUrl,
      };

      const docRef = doc(db, "news", id);
      await updateDoc(docRef, updatedData);

      setFormLoading(false);
      toast({
        title: "Data updated successfully",
        status: "success",
        isClosable: true,
        duration: 3000,
      });
    } catch (error) {
      console.error("Error updating document: ", error);
      setFormLoading(false);
      toast({
        title: "Error updating data: " + error,
        status: "error",
        isClosable: true,
        duration: 3000,
      });
    }
  };

  if (loading || formLoading) {
    return <Spinner />;
  }

  if (!newsItem) {
    return (
      <Center>
        <Text>Error fetching data</Text>
      </Center>
    );
  }

  return (
    <Box as="form" onSubmit={handleSubmit} p={4}>
      <FormControl mb={4} isRequired>
        <FormLabel>Title</FormLabel>
        <Input
          name="title"
          value={formData.title}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl mb={4} isRequired>
        <FormLabel>Content</FormLabel>
        <Textarea
          name="content"
          value={formData.content}
          onChange={handleInputChange}
        />
      </FormControl>
      <FormControl mb={4}>
        <FormLabel>Link</FormLabel>
        <Input name="link" value={formData.link} onChange={handleInputChange} />
      </FormControl>
      <FormControl mb={4} isRequired>
        <FormLabel>Category</FormLabel>
        <Select
          name="category"
          value={formData.category}
          onChange={handleInputChange}
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl mb={4}>
        <FormLabel>Image</FormLabel>
        <Input type="file" onChange={handleImageChange} />
        <Center mt={4}>
          <Image
            src={imageFile ? formData.image : newsItem.image}
            alt="News Image"
            objectFit="cover"
            fallback={<Spinner />}
          />
        </Center>
      </FormControl>
      <Center mt={4}>
        <Button type="submit" colorScheme="blue" isLoading={formLoading}>
          Update Changes
        </Button>
      </Center>
    </Box>
  );
};

export default NewsEditPage;
